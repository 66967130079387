import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FinChart = ({ chartId, chart }) => {
    // Check if chart data exists for the given chartId
    if (!chart) {
        return (
            <span>[Error: chart {chartId} not found]</span>
        );
    }

    // Extract chart data from the provided chart prop
    const { chart_name, x_axis_label, y_axis_label, x_series_coords, y_series_coords } = chart;

    // Prepare data for the chart
    const data = {
        labels: x_series_coords,
        datasets: [
            {
                label: y_axis_label,
                data: y_series_coords,
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Disable aspect ratio to manually control height/width
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: chart_name ? `${chart_name} (ID: ${chartId})` : `Chart for ID: ${chartId}`,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: x_axis_label,
                },
            },
            y: {
                title: {
                    display: true,
                    text: y_axis_label,
                },
            },
        },
    };

    // Calculate a dynamic width based on the number of data points, with a sensible maximum width.
    const dynamicWidth = Math.min(600, 200 + x_series_coords.length * 50);

    return (
        <div style={{ width: `${dynamicWidth}px`, height: '300px', marginleft: 0 }}> {/* Dynamic width based on data points, centered */}
            {/* <h3>{chart_name ? chart_name : `Financial Chart - ${chartId}`}</h3> */}
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default FinChart;
