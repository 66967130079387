// src/pages/ChatPage.js
import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Toolbar, Tooltip, Typography, Box, Menu, Chip, Modal, IconButton, Select, Switch, MenuItem, Paper, Divider, TextField, Button, CircularProgress } from '@mui/material';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // For recording button
import StopIcon from '@mui/icons-material/Stop'; // For stop button
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatMessage from '../models/ChatMessage';
import MessagesContainer from '../components/MessagesContainer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';

const ChatPage = ({ toggleTheme, theme }) => {
  // const [model, setModel] = React.useState('gpt-3.5-turbo');
  const [messages, setMessages] = React.useState([]);
  const [inputtext, setInputText] = React.useState("")
  const [inProgress, setInProgress] = React.useState(false);
  const messagesEndRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalContent, setModalContent] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState('text');
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const modeRef = useRef('text'); // Ref to track the latest mode
  const messagesRef = useRef([]); // Ref to track the latest messages
  const [ttsInProgress, setTtsInProgress] = useState(false);
  const audioRef = useRef(null);
  const [audioPlaying, setAudioPlaying] = useState(false);

  // const handleSnackbarClose = () => {
  //   setSnackbarOpen(false);
  // };

  // Update the modeRef value whenever mode changes
  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);




  // const mediaTypes = [
  //   'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
  //   'video/webm; codecs="vp8, vorbis"',
  //   'audio/mp4; codecs="mp4a.40.2"',
  //   'audio/webm; codecs="vorbis"',
  //   'video/ogg; codecs="theora"',
  //   'audio/ogg; codecs="vorbis"',
  //   'audio/wav; codecs="1"',  // WAV PCM 16bit LE signed with header (wav16)
  //   'audio/pcm; codecs="1"',  // PCM 16bit LE signed without header (pcm16)
  //   'audio/ogg; codecs="opus"',  // opus in ogg container
  //   'audio/alaw',  // ALAW without header
  //   'audio/mpeg',  // MP3 format
  // ];

  // const checkMediaSupport = () => {
  //   mediaTypes.forEach(type => {
  //     if (MediaSource.isTypeSupported(type)) {
  //       console.log(`Supported media type: ${type}`);
  //     } else {
  //       console.log(`Unsupported media type: ${type}`);
  //     }
  //   });
  // };

  // checkMediaSupport();


  // const ttsRequest = (text) => {
  //   const formData = new FormData();
  //   formData.append('text', text); // Send the response text to TTS API
  //   const startTime = Date.now();

  //   setTtsInProgress(true);
  //   axios.post(`${process.env.REACT_APP_API_BASE_URL}/tts/`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //     responseType: 'blob', // We expect binary audio data
  //   })
  //     .then(response => {
  //       const endTime = Date.now(); // Capture the end time
  //       const timeTaken = endTime - startTime; // Calculate the time taken
  //       console.log(`TTS request took ${timeTaken} milliseconds.`); // Log the time in milliseconds

  //       const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       const audio = new Audio(audioUrl);

  //       audioRef.current = audio;
  //       audio.play(); // Play the audio returned from the TTS API
  //       setAudioPlaying(true);
  //       audio.onended = () => {
  //         setAudioPlaying(false); // Audio finished playing
  //       };
  //       setTtsInProgress(false);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching audio from TTS API:', error);
  //       setTtsInProgress(false);
  //     });
  // };


  //simple without streaming:
  // const ttsRequest = (text) => {
  //   const formData = new FormData();
  //   formData.append('text', text);
  
  //   setTtsInProgress(true);
  //   axios.post(`${process.env.REACT_APP_API_BASE_URL}/tts/`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //     responseType: 'blob',
  //   })
  //     .then(response => {
  //       const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       const audio = new Audio(audioUrl);
  //       audio.play(); // Play the audio to verify if it's correct
  //       setTtsInProgress(false);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching audio from TTS API:', error);
  //       setTtsInProgress(false);
  //     });
  // };
  
  const ttsRequest = (text) => {
    // Remove all substrings matching the pattern [SOURCE:N]
    const cleanedText = text.replace(/\[SOURCE:\d+\]/g, '');
  
    // Create the URL with the cleaned text as a query parameter
    const ttsUrl = `${process.env.REACT_APP_API_BASE_URL}/tts/?text=${encodeURIComponent(cleanedText)}`;
    
    // Create a new Audio element with the streaming URL as the source
    setAudioPlaying(true);
    const audio = new Audio(ttsUrl);
    audioRef.current = audio;
  
    // Play the audio as it streams from the backend
    audio.play();
  
    // Handle the end of the audio stream
    audio.onended = () => {
      console.log('Audio playback finished.');
      setAudioPlaying(false);
    };
  
    // Handle errors during playback
    audio.onerror = (error) => {
      console.error('Error during audio playback:', error);
      setAudioPlaying(false);
    };
  };
  


  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset the audio playback
      setAudioPlaying(false); // Update state to reflect that audio is stopped
    }
  };

  const handleQueryRequest = (query) => {
    setInProgress(true);
    setMessages(prevMessages => [...prevMessages, new ChatMessage(query, 'user')]); // Add user query message
    setMessages(prevMessages => [...prevMessages, new ChatMessage("", 'assistant')]); // Add placeholder for assistant's response

    const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}/stream_query_i/?query_str=${encodeURIComponent(query)}`);

    eventSource.onopen = () => {
      console.log('Connection to server opened.');
    };

    eventSource.onerror = (error) => {
      console.error('Error in SSE connection:', error);
      if (error.readyState === EventSource.CLOSED) {
        console.log('SSE connection closed.');
      } else {
        console.log('A real error occurred.');
      }
      setInProgress(false);
      eventSource.close();

      setTimeout(() => {
        // Use the current mode from the ref
        if (modeRef.current === 'voice') {


          // Use the latest messages from the ref
          const finalResponseText = messagesRef.current[messagesRef.current.length - 1].text;
          console.log("mode is voice, triggering TTS", finalResponseText);
          ttsRequest(finalResponseText);  // Send the response text to TTS API and play audio
        }
      }, 200);

    };

    // Listen for custom "done" event signaling the end of the stream
    eventSource.addEventListener('finish_event', (e) => {
      console.log("Stream ended normally.");
      setInProgress(false);
      eventSource.close();  // Close the connection when done
      // If voice mode is active, trigger the TTS request with the final response text
      // console.log("modeRef=", modeRef.current)
      // console.log("mode=", mode)
      // Use the current mode from the ref

      setTimeout(() => {
        // Use the current mode from the ref
        if (modeRef.current === 'voice') {


          // Use the latest messages from the ref
          const finalResponseText = messagesRef.current[messagesRef.current.length - 1].text;
          console.log("mode is voice, triggering TTS", finalResponseText);
          ttsRequest(finalResponseText);  // Send the response text to TTS API and play audio
        }
      }, 200);



      // const finalResponseText = messagesRef.current[messagesRef.current.length - 1].text;
      // console.log("mode is voice, triggering TTS",finalResponseText);
      // ttsRequest(finalResponseText);


    });

    eventSource.addEventListener('references', (e) => {
      const references = JSON.parse(e.data);
      updateLatestMessageWithRefs(references.references, setMessages);
    });

    eventSource.addEventListener('new_message', (e) => {
      const data = JSON.parse(e.data);
      const decodedStr = decodeURIComponent(escape(window.atob(data.message)));
      updateLatestMessageWithNewMessage(decodedStr, setMessages);
    });

    eventSource.addEventListener('intermediate_result', (e) => {
      const data = JSON.parse(e.data);
      const decodedStr = decodeURIComponent(escape(window.atob(data.message)));
      updateLatestMessageWithIntermediateResults(decodedStr, setMessages);
    });

    eventSource.addEventListener('chat_request_info', (e) => {
      const data = JSON.parse(e.data);
      setMessages((prevMessages) => {
        const currentMessage = { ...prevMessages[prevMessages.length - 1] };
        currentMessage.chat_request_id = data.chat_request_id;
        return [...prevMessages.slice(0, prevMessages.length - 1), currentMessage];
      });
    });

    eventSource.addEventListener('draw_chart', (e) => {
      const data = JSON.parse(e.data);
      updateLatestMessageWithChart(data, setMessages);
    });

    // setInProgress(false);
  };



  const handleStartRecording = () => {
    if (recording) {
      // Stop recording
      mediaRecorder.stop();
      setRecording(false);
    } else {
      // Start recording
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          // Set MIME type to OGG for smaller size and good quality
          const options = { mimeType: 'audio/mp4' };

          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.error(`${options.mimeType} is not supported in this browser.`);
            return;
          }

          const recorder = new MediaRecorder(stream, options);
          setMediaRecorder(recorder);
          const chunks = [];
          recorder.ondataavailable = e => {
            chunks.push(e.data);
          };
          recorder.onstop = () => {
            const audioBlob = new Blob(chunks, { type: options.mimeType });
            sendAudioToBackend(audioBlob); // Send the recorded OGG audio to backend
          };
          recorder.start();
          setAudioChunks(chunks);
          setRecording(true);
        })
        .catch(err => {
          console.error('Error accessing microphone:', err);
        });
    }
  };

  const sendAudioToBackend = (audioBlob) => {
    const formData = new FormData();
    formData.append('audio_file', audioBlob, 'query.webm');

    setInProgress(true);

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/voice_query/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'json',
    })
      .then(response => {
        const textReply = response.data.text_reply;
        setInputText(""); // Clear input

        // Check if textReply is not empty
        if (textReply && textReply.trim() !== "") {
          handleQueryRequest(textReply); // Use the shared query handling function with transcribed text
        } else {
          setAlertMessage("Распознавание голоса вернуло пустой текст. Попробуйте еще раз.");
          setShowAlert(true);  // Show Alert when text is empty
          setInProgress(false);
        }
      })
      .catch(error => {
        console.error('Error sending audio to backend:', error);
        setAlertMessage("Ошибка при распознавании голоса");
        setShowAlert(true);  // Show Alert when text is empty
        setInProgress(false);
      });
  };


  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  };


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
    handleMenuClose();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateLatestMessageWithChart = (data, setMessages) => {
    setMessages((prevMessages) => {
      const currentMessage = { ...prevMessages[prevMessages.length - 1] };

      // Add the new chart data to the current message's charts hash map
      const chartData = {
        chart_id: data.chart_id,
        chart_name: data.chart_name,
        x_axis_label: data.x_axis_label,
        y_axis_label: data.y_axis_label,
        x_series_coords: data.x_series_coords,
        y_series_coords: data.y_series_coords,
      };

      if (!currentMessage.charts) {
        currentMessage.charts = {}; // Ensure charts hash map exists
      }

      currentMessage.charts[data.chart_id] = chartData; // Store chart data by chart_id

      return [
        ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
        currentMessage, // The updated last message with new chart data
      ];
    });
  };



  const updateLatestMessageWithRefs = (references, setMessages) => {
    setMessages((prevMessages) => {
      const currentMessage = { ...prevMessages[prevMessages.length - 1] };
      currentMessage.references = references;
      // console.log(currentMessage.references)
      return [
        ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
        currentMessage, // The updated last message
      ];
    });
  };

  const updateLatestMessageWithIntermediateResults = (inter_results_str, setMessages) => {
    setMessages((prevMessages) => {
      const currentMessage = { ...prevMessages[prevMessages.length - 1] };

      currentMessage.inter_results.push({
        result: inter_results_str,
        timestamp: new Date().toISOString() // Add the timestamp when the result is added
      });
      return [
        ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
        currentMessage, // The updated last message with new intermediate result
      ];
    });
  };


  const updateLatestMessageWithNewMessage = (newMessage, setMessages) => {
    setMessages((prevMessages) => {
      const currentMessage = { ...prevMessages[prevMessages.length - 1] };
      currentMessage.text = currentMessage.text + newMessage;
      return [
        ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
        currentMessage, // The updated last message
      ];
    });
  };


  // const handleModelChange = (event) => {
  //   setModel(event.target.value);
  // };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);  // This effect runs whenever `messages` changes

  useEffect(() => {
    const welcomeMessage = new ChatMessage(
      `# Краткая инструкция
  
  В настоящее время Помощник может отвечать на вопросы из трех баз данных:
  1. База данных новостей из 20 телеграм каналов с финансовой тематикой. 
  2. База данных официальных отчетных документов за 2023 год по 45 компаниям, которые входят в индекс Moex (подробный список по ссылке выше).
  3. База данных финансовых показателей и мультипликаторов по всем компаниям, торгующихся на Московской бирже.
  
  ## Примеры вопросов:
  - Какая прибыль у Сбербанка в 2023 году?
  - Сравни прибыль Лукойла и Роснефти за 2023 год.
  - Какие новости за последние 2 недели по расчетам с китайскими банками?  (обратите внимание - можно указать временной интервал для поиска по новостям)
  - Прогнозы по ключевой ставке ЦБ РФ
  - Дай топ 10 компаний по выручке в 2023 году и построй график
  
  Помощник ориентирован на конкретные вопросы по определенной теме. Это означает, что вопросы, требующие суммаризации большого объема информации, например 'О какой компании чаще всего говорили в новостях за последний месяц?', будут вызывать затруднения.
  
  ## Обратная связь
  После получения ответа, пожалуйста, используйте кнопки обратной связи — эта информация используется для улучшения Помощника.`,
      "first_message"
    );

    setMessages([welcomeMessage]);
  }, []);

  // const handleSendMessage = () => {
  //   if (inputtext === "") {
  //     return;
  //   }
  //   if (inProgress) {
  //     // show message to user that he can't submit new query until current one is finished
  //     alert("Пожалуйста подождите, пока текущий запрос не завершится");
  //     return;
  //   }
  //   setInputText("");
  //   setInProgress(true);
  //   setMessages((prevMessages) => [...prevMessages, new ChatMessage(inputtext, "user")])
  //   setMessages((prevMessages) => [...prevMessages, new ChatMessage("", "assistant")])
  //   const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}/stream_query_i/?query_str=${inputtext}`);
  //   eventSource.onopen = () => {
  //     console.log('Connection to server opened.');
  //   };

  //   eventSource.onerror = (error) => {
  //     // observer.error(error);
  //     console.log('Error in SSE connection:');
  //     eventSource.close();
  //     setInProgress(false);
  //   };
  //   eventSource.addEventListener('references', async (e) => {
  //     console.log("references event received")
  //     const references = JSON.parse(e.data);
  //     updateLatestMessageWithRefs(references.references, setMessages);
  //     // setReferences(references.references);
  //   });
  //   eventSource.addEventListener('new_message', async (e) => {
  //     const data = JSON.parse(e.data);
  //     const decodedStr = decodeURIComponent(escape(window.atob(data.message)))
  //     updateLatestMessageWithNewMessage(decodedStr, setMessages);
  //     // message_to_fill.text=decodedStr

  //   });
  //   eventSource.addEventListener('intermediate_result', async (e) => {
  //     const data = JSON.parse(e.data);
  //     const decodedStr = decodeURIComponent(escape(window.atob(data.message)))
  //     updateLatestMessageWithIntermediateResults(decodedStr, setMessages);
  //   });
  //   eventSource.addEventListener('chat_request_info', async (e) => {
  //     const data = JSON.parse(e.data);
  //     setMessages((prevMessages) => {
  //       const currentMessage = { ...prevMessages[prevMessages.length - 1] };
  //       currentMessage.chat_request_id = data.chat_request_id;
  //       return [
  //         ...prevMessages.slice(0, prevMessages.length - 1), // All messages except the last one
  //         currentMessage, // The updated last message
  //       ];
  //     });

  //   });
  //   eventSource.addEventListener('draw_chart', async (e) => {
  //     const data = JSON.parse(e.data);
  //     updateLatestMessageWithChart(data, setMessages);
  //   });

  //   // eventSource.addEventListener("chat_request_info", (e) => {
  //   //   const data = JSON.parse(e.data);
  //   //   console.log(`onmessage1 ${data.chat_request_id}`);
  //   // });
  // };

  const handleSendMessage = () => {
    if (inputtext === "") {
      return;
    }
    if (inProgress) {
      alert("Пожалуйста подождите, пока текущий запрос не завершится");
      return;
    }

    setInputText(""); // Clear input field
    handleQueryRequest(inputtext); // Use shared query handling function
  };



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>

          {/* Left-aligned Tooltip Menu Icon */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Tooltip title="Открыть меню"> */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            {/* </Tooltip> */}
          </Box>

          {/* Center-aligned Title */}
          <Typography variant="h6" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            Помощник трейдера
          </Typography>

          {/* Right-aligned Chip and Switch */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>

            <Switch
              checked={theme === 'dark'}
              onChange={toggleTheme}
              inputProps={{ 'aria-label': 'Theme switcher' }}
            />
          </Box>

        </Toolbar>

        {/* Dropdown Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleOpenModal('Список известных проблем')}>
            Список известных проблем
          </MenuItem>
          <MenuItem onClick={() => handleOpenModal('Инструкции')}>
            Инструкции
          </MenuItem>
          <MenuItem><Chip label="Список документов" component={Link} to="/documents" clickable sx={{ marginRight: 2 }} />
          </MenuItem>
        </Menu>
      </AppBar>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalContent}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalContent === 'Список известных проблем' ? (
              <ul>
                <li>
                  В настоящее время в базе присутствуют отчетные документы только за 2023 год.
                  Документов за первое полугодие 2024 нет (скоро будут загружены)
                </li>
                <li>Помощник не умеет отвечать на вопросы, которые требуют последовательного анализа большого объема данных. Т.е. вопрос 'О какой компании больше всего говорилось в новостях' вызовет сложности, если этой информации нет в какой-либо новости.</li>
                <li>Пока помощник не поддерживает контекст, т.е. он не поймет запрос "уточни такие-то моменты из своего предыдущего ответа"</li>
              </ul>
            ) : (
              'Здесь будут описаны нюансы использования поиска'
            )}
          </Typography>
        </Box>
      </Modal>


      {/* <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}> */}
      {/* <Box sx={{ width: 200, padding: 2, borderRight: '1px solid #ccc' }}>

          <Select
            value={model}
            onChange={handleModelChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
            <MenuItem value="gpt-4.0">gpt-4.0</MenuItem>
          </Select>

        </Box> */}

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <MessagesContainer messages={messages}
          messagesEndRef={messagesEndRef}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
          {mode === 'text' ? (
            <>
              <TextField
                variant="outlined"
                value={inputtext}
                placeholder="Спросить Помощника"
                fullWidth
                sx={{ marginRight: 2 }}
                disabled={inProgress}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
              />
              <Button variant="contained" onClick={handleSendMessage} disabled={inProgress}>
                Ввод
              </Button>
              <Button variant="contained" onClick={() => setMode('voice')} sx={{ marginLeft: 2 }}>
                Голос
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => { handleStartRecording(); stopAudio(); }}
                disabled={inProgress || ttsInProgress}
                startIcon={recording ? <StopIcon /> : <FiberManualRecordIcon sx={{ color: 'red' }} />} >
                {recording ? 'Закончить запись' : 'Начать запись'}
              </Button>
              <Button variant="contained" onClick={() => { setMode('text'); stopAudio(); }} sx={{ marginLeft: 2 }} disabled={ttsInProgress}>
                Выйти из голосового режима
              </Button>
              {ttsInProgress && (
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                  <CircularProgress size={24} />
                  <Typography variant="body1" sx={{ marginLeft: 1 }}>
                    Голосовой ответ...
                  </Typography>
                </Box>
              )}
              {audioPlaying && (
                <Button variant="contained" color="secondary" onClick={stopAudio} sx={{ marginLeft: 2 }}>
                  STOP
                </Button>
              )}
            </>
          )}
        </Box>
        {showAlert && (
          <Alert severity="warning" onClose={() => setShowAlert(false)}>
            {alertMessage}
          </Alert>
        )}
      </Box>
      {/* </Box> */}
    </Box>
  );
};

export default ChatPage;
