// models/ChatMessage.js
class ChatMessage {
    /**
     * @param {string} text - The message text
     * @param {string} role - The role of the message
     */
    constructor(text, role) {
        this.text = text;
        this.role = role;
        this.references = null;
        this.chat_request_id = null;
        this.inter_results = []; // Initialize as an empty array for intermediate results
        this.created_at = new Date().toISOString(); // Log message creation time
        this.charts = {}; // Initialize as an empty object for storing charts by chart_id
    }

    /**
     * Adds an intermediate result to the inter_results array with a timestamp
     * @param {string} result - The intermediate result to add
     */
    addIntermediateResult(result) {
        this.inter_results.push({
            result,
            timestamp: new Date().toISOString(), // Log when this result is added
        });
    }

    /**
     * Adds a chart to the charts hash map
     * @param {string} chart_id - The unique identifier for the chart
     * @param {object} chartData - The chart data to add
     */
    addChart(chart_id, chartData) {
        this.charts[chart_id] = chartData; // Add chart data using chart_id as the key
    }
}


export default ChatMessage;
