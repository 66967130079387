// components/DisplayedMessage.js

import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MessageSourceLink from './MessageSourceLink';
import axios from 'axios';
import ReferenceSources from './ReferenceSources';
import FeedbackButtons from './FeedbackButtons';
import IntermediateResults from './IntermediateResults';
import FinChart from './FinChart';

const DisplayedMessage = ({ message }) => {
    const [referenceDetails, setReferenceDetails] = useState(null);
    const [ref_loading, setRefLoading] = useState(false);
    const [selectedSource, setSelectedSource] = useState(null);
    const [responseFirstCharLoading, setResponseFirstCharLoading] = useState(true);
    const [showResults, setShowResults] = useState(true);

    const handleSourceClick = (key) => {
        const sourceDoc = getSourceDoc(key);
        setSelectedSource(sourceDoc); // Set selected source, opening the modal
    };
    const getSourceDoc = (ref_id) => {
        const request_id = message?.references?.reference_dict[ref_id][0];
        const request_index = message?.references?.reference_dict[ref_id][1];
        // console.log("getSourceDoc", ref_id,request_id, request_index);
        // console.log("referenceDetails",referenceDetails);
        let doc = {}
        try {
            doc["title"] = referenceDetails[request_id][request_index]["node"]["metadata"]["fr_source_doc_name"];
            doc["text"] = referenceDetails[request_id][request_index]["node"]["text"];
            // doc["fr_coords"] = referenceDetails[request_id][request_index]["node"]["metadata"]["fr_coords"];
            // doc["doc_id"] = referenceDetails[request_id][request_index]["node"]["relationships"]["1"]["node_id"];
            doc["fr_coords"] = referenceDetails[request_id]?.[request_index]?.["node"]?.["metadata"]?.["fr_coords"] ?? null;
            doc["doc_id"] = referenceDetails[request_id]?.[request_index]?.["node"]?.["relationships"]?.["1"]?.["node_id"] ?? null;
        } catch (error) {
            doc["title"] = "Документ не найден (ошибка)"
            doc["text"] = "";
            doc["fr_coords"] = {};
            doc["doc_id"] = null
        }
        // console.log(doc);
        return doc;
    };

    

    useEffect(() => {
        // console.log("asd")
        if (message.text) {
            setShowResults(false);
            setResponseFirstCharLoading(false);
        }
    }, [message.text]);

    // const replaceSourceWithComponent = (text, node, referenceDetails, references) => {
    //     const sourcePattern = /\[SOURCE:(\d+)\]/g;
    //     console.log("ReplaceSourceWithComponent")
    //     console.log(text)
    //     if (typeof text !== 'string') {

    //         return text; // Return the original text or handle it as needed
    //     }
    //     const parts = text.split(sourcePattern).map((part, index) => {
    //         if (index % 2 === 1) {
    //             return (
    //                 <MessageSourceLink key={index} id={part} references={references} referenceDetails={referenceDetails} sourceName={null} onClick={handleSourceClick} />
    //             );
    //         }
    //         return part;
    //     });
    //     return parts;
    // };


    const replaceSourceWithComponent = (text, node, referenceDetails, references) => {
        const sourcePattern = /\[SOURCE:\d+\]/; // Non-capturing pattern for SOURCE
        const chartPattern = /\[CHART:\d+\]/; // Non-capturing pattern for CHART
    
        // Combine patterns to use in the split function
        const combinedPattern = new RegExp(`(${sourcePattern.source}|${chartPattern.source})`);
    
        // Function to replace strings containing [SOURCE:x] or [CHART:x] with components
        const processText = (str) => {
            if (typeof str !== 'string') {
                return str; // If not a string, return as is
            }
    
            return str.split(combinedPattern).map((part, index) => {
                // Check if part is defined and is a string before calling match
                if (!part || typeof part !== 'string') {
                    return part; // If part is undefined or not a string, return it as is
                }
    
                const sourceMatch = part.match(sourcePattern);
                const chartMatch = part.match(chartPattern);
    
                if (sourceMatch) {
                    // Extract the number manually, since we no longer capture it directly
                    const sourceId = part.match(/\d+/)[0]; // Extract digits manually
                    return (
                        <MessageSourceLink 
                            key={index} 
                            id={sourceId} 
                            references={references} 
                            referenceDetails={referenceDetails} 
                            sourceName={null} 
                            onClick={handleSourceClick} 
                        />
                    );
                } else if (chartMatch) {
                    // Extract the chartId manually
                    const chartId = part.match(/\d+/)[0]; // Extract digits manually
                    return (
                        <FinChart 
                            key={index} 
                            chartId={chartId}
                            chart={message.charts[chartId]}
                        />
                    );
                }
    
                return part; // Return non-matching part as is
            });
        };
    
        // If text is an array, process each item
        if (Array.isArray(text)) {
            return text.map((item) => processText(item));
        }
    
        // If text is a string, process it directly
        return processText(text);
    };
    
    
    
    // const replaceSourceWithComponent = (text, node, referenceDetails, references) => {
    //     const sourcePattern = /\[SOURCE:(\d+)\]/g;
    //     // console.log("ReplaceSourceWithComponent");
    //     // console.log(text);

    //     // Function to replace strings containing [SOURCE:x] with components
    //     const processText = (str) => {
    //         if (typeof str !== 'string') {
    //             return str; // If not a string, return as is
    //         }

    //         return str.split(sourcePattern).map((part, index) => {
    //             if (index % 2 === 1) {
    //                 return (
    //                     <MessageSourceLink key={index} id={part} references={references} referenceDetails={referenceDetails} sourceName={null} onClick={handleSourceClick} />
    //                 );
    //             }
    //             return part;
    //         });
    //     };

    //     // If text is an array, process each item
    //     if (Array.isArray(text)) {
    //         return text.map((item) => processText(item));
    //     }

    //     // If text is a string, process it directly
    //     return processText(text);
    // };

    const fetchReferences = async () => {

        if (message.references && message.references?.request_list?.length > 0) {
            // console.log("Fetching references:", message.references);
            setRefLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get_sources`, {
                    params: { request_ids: message.references.request_list.join(',') },
                });
                setReferenceDetails(response.data);
                // console.log(`Response for references:`, response.data);
                setRefLoading(false);
            } catch (error) {
                console.error('Error fetching sources:', error);
                if (error.response) {
                    console.error('Response:', error.response.data);
                }
            }
        } else {
            // setSources([]); // Clear sources if no references are provided
        }

        setRefLoading(false);
    };

    useEffect(() => {
        if (message.references) {
            fetchReferences();
        }
    }, [message.references]);

    return (
        <Box sx={{ padding: 1 }}>
            {message.inter_results?.length > 0 && (
                <IntermediateResults
                    interResults={message.inter_results}
                    createdAt={message.created_at}
                    showResults={showResults}
                    setShowResults={setShowResults}
                />
            )}
            {message.role == "assistant" && (<Typography variant="h6">Ответ:</Typography>)}
            {message.role == "user" && (<Typography variant="h6">Запрос:</Typography>)}
            <Box>{responseFirstCharLoading && <CircularProgress size={24} />}</Box>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    p: ({ node, children }) => (
                        <div style={{
                            display: 'block',
                            margin: '1em 0',
                            lineHeight: '1.5',
                            fontSize: '16px'
                        }}>
                            {replaceSourceWithComponent(children, node, referenceDetails, message.references)}
                        </div>
                    ),
                    li: ({ node, children }) => (
                        <li>{replaceSourceWithComponent(children, node, referenceDetails, message.references)}</li>
                    ),
                }}
            >
                {message.text}
            </ReactMarkdown>

            {ref_loading && <CircularProgress size={24} />}
            <FeedbackButtons chatRequestId={message.chat_request_id} />
            {<ReferenceSources references={message.references} sources={referenceDetails} selectedSource={selectedSource} setSelectedSource={setSelectedSource} getSourceDoc={getSourceDoc} handleSourceClick={handleSourceClick} />}
            {/* {referenceDetails && (
                <Box sx={{ marginTop: 2 }}>
                    {referenceDetails.map((ref, index) => (
                        <Box key={index} sx={{ marginBottom: 1 }}>
                            <strong>Reference {index + 1}:</strong> {ref.title} - {ref.description}
                        </Box>
                    ))}
                </Box>
            )} */}
        </Box>
    );
};

export default DisplayedMessage;
